import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const mapStateToProps = (store) => {
  const { buttonText } = store.mainState;
  return { buttonText };
};

function AddCategory({ buttonText }) {
  const clearFileInputRef = useRef();
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccessImgUrl, setIsSuccessImgUrl] = useState(false);
  const [categoryDescription, setCategoryDescription] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", image);
    setLoading(true);
    axios({
      method: "POST",
      // url: "http://13.127.139.254:8080/foocery/v1/api//employee/category/add-image",
      //   url: "http://app.gurugfood.com:8080/foocery/v1/api/employee/category/add-image",
      url: `${process.env.REACT_APP_API}/foocery/v1/api/employee/category/add-image`,
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setImageUrl(response.data.imageUrl);
        setLoading(false);
        setIsSuccessImgUrl(!isSuccessImgUrl);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [image]);

  const handleAddCategorySubmit = (e) => {
    e.preventDefault();
    if (isSuccessImgUrl) {
      dispatch({
        type: "POST_CATEGORY_REQUEST",
        payload: {
          categoryName: title,
          description: categoryDescription,
          imageUrl: imageUrl,
        },
      });
    } else {
      dispatch({
        type: "POST_CATEGORY_REQUEST",
        payload: {
          categoryName: title,
          description: categoryDescription,
        },
      });
    }
    setTitle("");
    setCategoryDescription("");
    clearFileInputRef.current.value = "";
    setImageUrl("");
    setIsSuccessImgUrl(false);
  };
  return (
    <div>
      <div className="Modal--heading">
        <p style={{ color: "black" }}>Add Category</p>
      </div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="image">Image</label>
              <input
                type="file"
                ref={clearFileInputRef}
                name="image"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Category Name</label>
              <input
                type="text"
                name="title"
                value={title}
                placeholder="Enter Category"
                onChange={(e) => setTitle(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Category Description</label>
              <input
                type="text"
                name="title"
                value={categoryDescription}
                placeholder="Category Description"
                onChange={(e) => setCategoryDescription(e.target.value)}
              />
            </p>
          </div>
          <div className="modal--container__group--buttons">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleAddCategorySubmit}
              style={{ marginBottom: "50px" }}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddCategory);
