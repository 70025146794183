import React from "react";
import Layout from "../../Layout/Layout";
import Footer from "../Z_Footer/Footer";
import myImage from "../../Images/foocery-logo-sm.png";

function Suport() {
  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Support</h4>
        </div>
        <div className="user_groups">
          <div className="support--container">
            <div className="support--container--image">
              <img src={myImage} alt="image" />
            </div>
            <div className="support--container--support">
              <div className="modal-orderDetails--heading">
                <div className="modal-orderDetails--heading__description">
                  <p style={{ color: "blue", marginBottom: "20px" }}>Foocery</p>
                  {/* <p>Maitidevi, Kathmandu, Nepal</p> */}
                  <p>1300287637, 0425 327 800</p>
                  <p>contact@foocery.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-container">
            <Footer />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Suport;
