import React from 'react'
import {BrowserRouter,Switch,Route} from 'react-router-dom'
import AdminController from '../Components/Admin Controller/AdminController'
import ChangePassword from '../Components/auth/ChangePassword'
import LogIn from '../Components/auth/SignIn/LogIn'
import Category from '../Components/Category/Category'
import ManageUsers from '../Components/Customers/ManageUsers'
import Dashboard from '../Components/Dashboard/Dashboard'
import Menu from '../Components/Menu/Menu'
import Notification from '../Components/Nofification/Notification'
import OrderManagement from '../Components/Orders/OrderManagement'
import Privacy from '../Components/Privacy/Privacy'
import PrivateRoute from '../Components/PrivateRoute/PrivateRoute'
import Suport from '../Components/Suport/Suport'
import Terms_nCondition from '../Components/Terms And Condition/Terms_nCondition'


function MainRoutes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path = '/' exact component = {LogIn} />
                <PrivateRoute path = '/dashboard' exact component = {Dashboard} />
                <PrivateRoute path = '/admin-room' exact component = {AdminController} />
                <PrivateRoute path = '/category' exact component = {Category} />
                <PrivateRoute path = '/Menu' exact component = {Menu} />
                <PrivateRoute path = '/order-manage' exact component = {OrderManagement} />
                <PrivateRoute path = '/support' exact component = {Suport} />
                <PrivateRoute path = '/privacy-policy' exact component = {Privacy} />
                <PrivateRoute path = '/terms-condition' exact component = {Terms_nCondition} />
                <PrivateRoute path = '/notification' exact component = {Notification} />
                <PrivateRoute path = '/manage-customer' exact component = {ManageUsers} />
                <Route path = '/change-password' exact component = {ChangePassword} />
            </Switch>
        </BrowserRouter>
    )
}

export default MainRoutes

