import { mergeMap,catchError,tap, flatMap, switchMap } from "rxjs/operators";
import {ofType} from 'redux-observable'
import {API} from '../BaseUrlProvider';
import { ADMIN_AUTH_REQUEST, FETCH_CATEGORY_BY_ID_REQUEST, FETCH_CATEGORY_LIST, FETCH_SUB_CATEGORY_REQUEST, POST_CATEGORY_REQUEST, PUT_CATEGORY_REQUEST } from "./actionType";
import { from, of } from "rxjs";
import {loginFailure, loginSuccess} from "./actions";

// Fetch Dashboard Data
export const fetchDashboardDataEpic = action$ => action$.pipe(
    ofType("FETCH_DASHBOARD_DATA_REQUEST"),
    mergeMap(action=> 
    from(API.get('/employee/dashboard')).pipe(
        mergeMap(response => of({type: "FETCH_DASHBOARD_DATA_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "FETCH_DASHBOARD_DATA_FAILURE",
        payload: error.response}))
    ))
)
// fetch Admin data
export const fetchAdminDataEpic = action$ => action$.pipe(
    ofType("FETCH_ADMIN_USERS_REQUEST"),
    mergeMap(action=> 
    from(API.get(`/admin/get-list?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&searchValue=${action.payload.myKeyword}&sortParam=${action.payload.sortingParam}`)).pipe(
        mergeMap((response)=> of({type: "FETCH_ADMIN_USERS_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "FETCH_ADMIN_USERS_FAILURE",
        payload: error.response}))
    ))
)
// fetch Admin profile
export const fetchAdminProfileEpic = action$ => action$.pipe(
    ofType("FETCH_ADMIN_USERS_PROFILE_REQUEST"),
    mergeMap(action=> 
    from(API.get('/employee/get-profile')).pipe(
        mergeMap((response)=> of({type: "FETCH_ADMIN_USERS_PROFILE_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "FETCH_ADMIN_USERS_PROFILE_FAILURE",
        payload: error.response}))
    ))
)
// set opening and closing time on Restaurnat
export const setOpeningClosingTimeEpic = action$ => action$.pipe(
    ofType("SET_CLOSING_OPENING_TIME_REQUEST"),
    mergeMap(action=> 
    from(API.put('/employee/update-open-close',action.payload)).pipe(
        mergeMap((response)=> of({type: "SET_CLOSING_OPENING_TIME_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "SET_CLOSING_OPENING_TIME_FAILURE",
        payload: error.response}))
    ))
)
// open restaurnt
export const openRestaurantEpic = action$ => action$.pipe(
    ofType("OPEN_RESTAURANT_REQUEST"),
    mergeMap(action=> 
    from(API.put('/employee/update-open-close',action.payload)).pipe(
        mergeMap((response)=> of({type: "OPEN_RESTAURANT_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "OPEN_RESTAURANT_FAILURE",
        payload: error.response}))
    ))
)
// close restaurant
export const closeRestaurantEpic = action$ => action$.pipe(
    ofType("CLOSE_RESTAURANT_REQUEST"),
    mergeMap(action=> 
    from(API.put('/employee/update-open-close',action.payload)).pipe(
        mergeMap((response)=> of({type: "CLOSE_RESTAURANT_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "CLOSE_RESTAURANT_FAILURE",
        payload: error.response}))
    ))
)
// Add admin
export const postAdminDataEpic = action$ => action$.pipe(
    ofType("POST_ADMIN_USERS_REQUEST"),
    mergeMap(action=> 
    from(API.post('/admin/register',action.payload)).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response)=> of({type: "POST_ADMIN_USERS_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "POST_ADMIN_USERS_FAILURE",
        payload: error.response}))
    ))
)
// Update Admin
export const updateAdminDataEpic = action$ => action$.pipe(
    ofType("UPDATE_ADMIN_USERS_REQUEST"),
    mergeMap(action=> 
    from(API.put('/admin/update-profile',action.payload)).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response)=> of({type: "UPDATE_ADMIN_USERS_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "UPDATE_ADMIN_USERS_FAILURE",
        payload: error.response}))
    ))
)

//authenticate admin epic
export const authenticateAdminEpic = action$ => action$.pipe(
    ofType(ADMIN_AUTH_REQUEST),
    mergeMap(action=> 
    from(API.post('/employee/authenticate',action.payload.adminCredencial)).pipe(
        mergeMap(response => of(loginSuccess(response.data))),
        catchError(error=> of(loginFailure(error.response)))
    ))
)
// // fetch Privacy 
export const fetchPrivacyEpic = action$ => action$.pipe(
    ofType("FETCH_PRIVACY_REQUEST"),
    mergeMap(action=> 
    from(API.get('/terms-privacy')).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response)=> of({type: "FETCH_PRIVACY_SUCCESS",
        payload: response.data.privacyPolicy})),
        catchError(error=> of({type: "FETCH_PRIVACY_FAILURE",
        payload: error.response}))
    ))
)
// // fetch terms and condition
export const fetchTermsAndConditionEpic = action$ => action$.pipe(
    ofType("FETCH_TERMS_CONDITION_REQUEST"),
    mergeMap(action=> 
    from(API.get('/terms-privacy')).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response)=> of({type: "FETCH_TERMS_CONDITION_SUCCESS",
        payload: response.data.termsAndCondition})),
        catchError(error=> of({type: "FETCH_TERMS_CONDITION_FAILURE",
        payload: error.response}))
    ))
)
// // updating privacy and policy
export const postPrivacy_Epic = action$ => action$.pipe(
    ofType("POST_PRIVACY_REQUEST"),
    mergeMap(action=> 
    from(API.post('/privacy-terms/admin/add-privacy-policy',action.payload.myPolicy)).pipe(
        // tap(response=> console.log("The response for privacy post",response)),
        mergeMap((response)=> of({type: "POST_PRIVACY_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "POST_PRIVACY_FAILURE",
        payload: error.response}))
    ))
)
// // updating terms and conditions
export const postTerms_n_condition_Epic = action$ => action$.pipe(
    ofType("POST_TERMS_N_CONDITION_REQUEST"),
    mergeMap(action=> 
    from(API.post('/privacy-terms/admin/add-terms-condition',action.payload.myPolicy)).pipe(
        // tap(response=> console.log("The response for privacy post",response)),
        mergeMap((response)=> of({type: "POST_TERMS_N_CONDITION_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "POST_TERMS_N_CONDITION_FAILURE",
        payload: error.response}))
    ))
)
// // Add Mass Notification
export const addNotificationEpic = action$ => action$.pipe(
    ofType("ADD_MASS_NOTIFICATION_REQUEST"),
    mergeMap(action=> 
    from(API.post('/admin/send/mass-notification',action.payload)).pipe(
        mergeMap((response)=> of({type: "ADD_MASS_NOTIFICATION_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "ADD_MASS_NOTIFICATION_FAILURE",
        payload: error.response}))
    ))
)
// // fetch user list epic

export const fetchUsersListEpic = action$ => action$.pipe(
    ofType("FETCH_USERS_LIST_REQUEST"),
    mergeMap(action => 
    from(API.get(`/admin/get-users?sortParam=VERIFIED&page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&searchValue=${action.payload.myKeyword}`)).pipe(
        mergeMap(res=> of({type: "FETCH_USERS_LIST_SUCCESS",
        payload: res.data})),
        catchError(error=> of({type: "FETCH_USERS_LIST_FAILURE",
        payload: error.response
        }))
    ))
)
// // fetch users by Id for description

export const fetchUserByIdEpic = action$ => action$.pipe(
    ofType("FETCH_USER_BY_ID_REQUEST"),
    mergeMap(action => 
    from(API.get(`/admin/get-user-by-id/${action.payload}`)).pipe(
        // tap(res=> console.log("The fetched category response",res.data)),
        mergeMap(res=> of({type: "FETCH_USER_BY_ID_SUCCESS",
        payload: res.data})),
        catchError(error=> of({type: "FETCH_USER_BY_ID_FAILURE",
        payload: error.response
        }))
    ))
)


// // // Enable User
export const enableUsersEpic = action$ => action$.pipe(
    ofType("ENABLE_USERS_REQUEST"),
    mergeMap(action=> 
    from(API.put('/admin/enable-users',action.payload)).pipe(
        mergeMap((response)=> of({type: "ENABLE_USERS_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "ENABLE_USERS_FAILURE",
        payload: error.response}))
    ))
)
// // // Disable user
export const disableUsersEpic = action$ => action$.pipe(
    ofType("DISABLE_USERS_REQUEST"),
    mergeMap(action=> 
    from(API.put('/admin/disable-users',action.payload)).pipe(
        mergeMap((response)=> of({type: "DISABLE_USERS_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "DISABLE_USERS_FAILURE",
        payload: error.response}))
    ))
)
// fetch category list epic

export const fetchCategoryEpic = action$ => action$.pipe(
    ofType("FETCH_CATEGORY_LIST_REQUEST"),
    mergeMap(action => 
    from(API.get(`/employee/category/get-active?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`)).pipe(
        // tap(res=> console.log("The fetched category response",res.data)),
        mergeMap(res=> of({type: "FETCH_CATEGORY_LIST_SUCCESS",
        payload: res.data})),
        catchError(error=> of({type: "FETCH_CATEGORY_LIST_FAILURE",
        payload: error.response}))
    ))
)
// fetch category list for menu drop down epic

export const fetchCategoryDropdownEpic = action$ => action$.pipe(
    ofType("FETCH_CATEGORY_LIST_DROPDOWN_REQUEST"),
    mergeMap(action => 
    from(API.get('/employee/category/get-active/drop-down')).pipe(
        // tap(res=> console.log("The fetched category response",res.data)),
        mergeMap(res=> of({type: "FETCH_CATEGORY_LIST_DROPDOWN_SUCCESS",
        payload: res.data})),
        catchError(error=> of({type: "FETCH_CATEGORY_LIST_DROPDOWN_FAILURE",
        payload: error.response}))
    ))
)

// posting category
export const postingCategoryEpic = action$ => action$.pipe(
    ofType("POST_CATEGORY_REQUEST"),
    mergeMap(action=> 
    from(API.post('/employee/category/add',action.payload)).pipe(
        // tap((response) => console.log("the response data from epic",response.data)),
        mergeMap(response => of({type: "POST_CATEGORY_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "POST_CATEGORY_FAILURE",
        payload: error.response}))
    ))
)
// // updating category
export const updatingCategoryEpic = action$ => action$.pipe(
    ofType("PUT_CATEGORY_REQUEST"),
    switchMap(action=> 
    from(API.post('/employee/category/update',action.payload)).pipe(
        mergeMap(response => of({type: "PUT_CATEGORY_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "PUT_CATEGORY_FAILURE",
        payload: error.response}))
    ))
)
// Enable Category
export const enableCategoryEpic = action$ => action$.pipe(
    ofType("ENABLE_CATEGORY_REQUEST"),
    switchMap(action=> 
    from(API.get(`/employee/category/activate/${action.payload.id}`)).pipe(
        // tap((response) => console.log("the response data from epic",response.data)),
        mergeMap(response => of({type: "ENABLE_CATEGORY_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "ENABLE_CATEGORY_FAILURE",
        payload: error.data}))
    ))
)
// Disable Category
export const disableCategoryEpic = action$ => action$.pipe(
    ofType("DISABLE_CATEGORY_REQUEST"),
    switchMap(action=> 
    from(API.get(`/employee/category/deactivate/${action.payload.id}`)).pipe(
        // tap((response) => console.log("the response data from epic",response.data)),
        mergeMap(response => of({type: "DISABLE_CATEGORY_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "DISABLE_CATEGORY_FAILURE",
        payload: error}))
    ))
)
// // fetch Menu items
export const fetchMenuListEpic = action$ => action$.pipe(
    ofType("FETCH_MENU_ITEM_REQUEST"),
    mergeMap(action=> 
    from(API.get(`/employee/menu/get-menu-by-catid/${action.payload.categoryId}`)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap(response => of({type: "FETCH_MENU_ITEM_SUCCESS",payload: response.data})),
        catchError(error=> of({type: "FETCH_MENU_ITEM_REQUEST",payload: error.response}))
    ))
)
// // Post Menu items
export const postMenuItemsEpic = action$ => action$.pipe(
    ofType("POST_MENU_ITEM_REQUEST"),
    mergeMap(action=> 
    from(API.post('/employee/menu/add',action.payload)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap(response => of({type: "POST_MENU_ITEM_SUCCESS",payload: response.data})),
        catchError(error=> of({type: "POST_MENU_ITEM_FAILURE",payload: error.response}))
    ))
)
// Update Menu items
export const updateMenuItemsEpic = action$ => action$.pipe(
    ofType("UPDATE_MENU_ITEM_REQUEST"),
    mergeMap(action=> 
    from(API.post('/employee/menu/update',action.payload)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap(response => of({type: "UPDATE_MENU_ITEM_SUCCESS",payload: response.data})),
        catchError(error=> of({type: "UPDATE_MENU_ITEM_FAILURE",payload: error.response}))
    ))
)
// Activate menu item
export const activateMenuItemsEpic = action$ => action$.pipe(
    ofType("ACTIVATE_MENU_ITEM_REQUEST"),
    mergeMap(action=> 
    from(API.get(`/employee/menu/activate/${action.payload.id}`)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap(response => of({type: "ACTIVATE_MENU_ITEM_SUCCESS",payload: response.data})),
        catchError(error=> of({type: "ACTIVATE_MENU_ITEM_FAILURE",payload: error.response}))
    ))
)
// Deactivate menu item
export const deactivateMenuItemsEpic = action$ => action$.pipe(
    ofType("DEACTIVATE_MENU_ITEM_REQUEST"),
    mergeMap(action=> 
    from(API.get(`/employee/menu/deactivate/${action.payload.id}`)).pipe(
        // tap((response) => console.log("the response data from fetchinGDataById epic",response.data)),
        mergeMap(response => of({type: "DEACTIVATE_MENU_ITEM_SUCCESS",payload: response.data})),
        catchError(error=> of({type: "DEACTIVATE_MENU_ITEM_FAILURE",payload: error.response}))
    ))
)
// get order list
export const fetchOrderListEpic = action$ => action$.pipe(
    ofType("FETCH_ORDER_LIST_REQUEST"),
    mergeMap(action=> 
    from(API.get(`/employee/get-orders-of-restaurant?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&orderStatus=${action.payload.orderStatus}`)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response)=> of({type: "FETCH_ORDER_LIST_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "FETCH_ORDER_LIST_FAILURE",
        payload: error.response}))
    ))
)
// get order details by Id
export const orderDetailsEpic = action$ => action$.pipe(
    ofType("ORDER_DETAILS_REQUEST"),
    mergeMap(action=> 
    from(API.get(`/employee/get-orders-of-restaurant/${action.payload}`)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response)=> of({type: "ORDER_DETAILS_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "ORDER_DETAILS_FAILURE",
        payload: error.response}))
    ))
)
// accept order 
export const acceptOrderEpic = action$ => action$.pipe(
    ofType("ACCEPT_ORDER_REQUEST"),
    mergeMap(action=> 
    from(API.put(`/employee/approve-order/${action.payload.id}`,action.payload.data)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response)=> of({type: "ACCEPT_ORDER_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "ACCEPT_ORDER_FAILURE",
        payload: error.response}))
    ))
)
// order ready status
export const setOrderReadyEpic = action$ => action$.pipe(
    ofType("SET_ORDER_READY_REQUEST"),
    mergeMap(action=> 
    from(API.put(`/employee/mark-as-order-as-ready/${action.payload.id}`,action.payload.data)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response)=> of({type: "SET_ORDER_READY_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "SET_ORDER_READY_FAILURE",
        payload: error.response}))
    ))
)
// fetch notification list
export const fetchNotificationEpic = action$ => action$.pipe(
    ofType("FETCH_NOTIFICATION_REQUEST"),
    mergeMap(action=> 
    from(API.get(`/employee/list-notification?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response)=> of({type: "FETCH_NOTIFICATION_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "FETCH_NOTIFICATION_FAILURE",
        payload: error.response}))
    ))
)
// notification set as viewed
export const makeSeenNotificationEpic = action$ => action$.pipe(
    ofType("MAKE_SEEN_NOTIFICATION_REQUEST"),
    mergeMap(action=> 
    from(API.get("/employee/mark-notification-as-viewed")).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response)=> of({type: "MAKE_SEEN_NOTIFICATION_SUCCESS",
        payload: response.data})),
        catchError(error=> of({type: "MAKE_SEEN_NOTIFICATION_FAILURE",
        payload: error.response}))
    ))
)
