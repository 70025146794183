import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const mapStateToProps = (store) => {
  const { buttonText, categoryListDropdown } = store.mainState;
  return { buttonText, categoryListDropdown };
};

function AddMenu({ buttonText, categoryListDropdown }) {
  const clearInputFileRef = useRef();
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccessImgUrl, setIsSuccessImgUrl] = useState(false);
  const [categoryDescription, setCategoryDescription] = useState("");
  const [itemPrice, setItemsPrice] = useState("");
  const [activeCategoryList, setActiveCategoryList] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", image);
    setLoading(true);
    axios({
      method: "POST",
      // url: "http://13.127.139.254:8080/foocery/v1/api/employee/category/add-image",
      //   url: "http://app.gurugfood.com:8080/foocery/v1/api/employee/category/add-image",
      url: `${process.env.REACT_APP_API}/foocery/v1/api/employee/menu/add-menu-image`,
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setImageUrl(response.data.imageUrl);
        setLoading(false);
        setIsSuccessImgUrl(!isSuccessImgUrl);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [image]);
  useEffect(() => {
    dispatch({
      type: "FETCH_CATEGORY_LIST_DROPDOWN_REQUEST",
    });
  }, []);

  useEffect(() => {
    let a =
      categoryListDropdown !== undefined &&
      categoryListDropdown.filter((item) => item.active === true);
    setActiveCategoryList(a);
  }, [categoryListDropdown]);

  const handleAddCategorySubmit = (e) => {
    e.preventDefault();
    if (imageUrl !== "") {
      dispatch({
        type: "POST_MENU_ITEM_REQUEST",
        payload: {
          itemName: title,
          description: categoryDescription,
          imageUrl: imageUrl,
          isAvailable: true,
          price: Number(itemPrice),
          categoryId: categoryId,
        },
      });
    } else {
      toast.warning("Image is Mandatory !!!");
    }
    setTitle("");
    setCategoryDescription("");
    clearInputFileRef.current.value = "";
    setImageUrl("");
    setIsSuccessImgUrl(false);
    setItemsPrice("");
  };
  return (
    <div>
      <div className="Modal--heading">
        <p style={{ color: "black" }}>Add Menu Item</p>
      </div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="image">Category Name</label>
              <select
                name="pageSize"
                onClick={(e) => {
                  setCategoryId(e.target.value);
                }}
              >
                <option>Select</option>
                {(activeCategoryList !== null) & (activeCategoryList !== false)
                  ? activeCategoryList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.categoryName}
                      </option>
                    ))
                  : null}
              </select>
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Item Name</label>
              <input
                type="text"
                name="title"
                value={title}
                placeholder="Item Name"
                onChange={(e) => setTitle(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Description</label>
              <input
                type="text"
                name="title"
                value={categoryDescription}
                placeholder="Description"
                onChange={(e) => setCategoryDescription(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Item Price</label>
              <input
                type="number"
                name="price"
                value={itemPrice}
                placeholder="Price"
                onChange={(e) => setItemsPrice(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="image">Image</label>
              <input
                type="file"
                ref={clearInputFileRef}
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </p>
          </div>
          <div className="modal--container__group--buttons">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleAddCategorySubmit}
              style={{ marginBottom: "50px" }}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddMenu);
