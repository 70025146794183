import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import { AiFillEdit } from 'react-icons/ai'
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { BsCheckCircle } from 'react-icons/bs'
import { connect, useDispatch } from 'react-redux'
import Footer from '../Z_Footer/Footer'
import { GiSkullCrossedBones } from 'react-icons/gi'
import { TiTick } from 'react-icons/ti'
import { BsX } from 'react-icons/bs'
import LoadingComponent from '../LoadingComponent'
import EditMenu from './EditMenu'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import moment from 'moment'

const mapStateToProps = store => {
    const { menuList, categoryList, isEnabledUsers, isLoadingOnLogin, isToogleSidebar, isAddedSuccessfully } = store.mainState
    return { menuList, categoryList, isEnabledUsers, isLoadingOnLogin, isToogleSidebar, isAddedSuccessfully }
}

function Menu({ menuList, categoryList, isEnabledUsers, isLoadingOnLogin, isToogleSidebar, isAddedSuccessfully }) {
    const [updateMenuModal, setUpdateMenuModal] = useState(false)
    const [editingItem, setEditingItem] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [activeCategoryList, setActiveCategoryList] = useState(null)
    const [categoryId, setCategoryId] = useState(null)
    const { itemsList } = menuList
    // Enable Disable category
    const [showSmModal, setShowSmModal] = useState(false);
    const [enableDisableItem, setEnableDisableItem] = useState({});
    const dispatch = useDispatch()
    useEffect(() => {
        let postsPerPage = 20;
        let currentPage = 1;
        dispatch({
            type: "FETCH_CATEGORY_LIST_REQUEST",
            payload: { postsPerPage, currentPage }
        })
    }, [])

    useEffect(() => {
        let a = categoryList.data !== undefined && categoryList.data.filter((item) => item.active === true)
        setActiveCategoryList(a);

    }, [categoryList])

    let pageNumbers = []

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage

    const currentPosts = itemsList !== undefined && itemsList.slice(indexOfFirstPost, indexOfLastPost)

    for (let i = 1; i <= Math.ceil(itemsList !== undefined && itemsList.length / postsPerPage); i++) {
        pageNumbers.push(i)
    }

    useEffect(() => {
        if (activeCategoryList !== null & activeCategoryList !== false) {
            if (activeCategoryList[0] !== undefined) {
                setCategoryId(activeCategoryList[0].id)
            }
        }
    }, [activeCategoryList])
    useEffect(() => {
        if (isEnabledUsers === "b") {
            setTimeout(() => {
                setShowSmModal(false);
                dispatch({
                    type: "FETCH_MENU_ITEM_REQUEST",
                    payload: { categoryId }
                })
            }, 1000);

        }
    }, [isEnabledUsers])
    useEffect(() => {
        if (isAddedSuccessfully === "yes") {
            if (categoryId !== undefined & categoryId !== null) {
                dispatch({
                    type: "FETCH_MENU_ITEM_REQUEST",
                    payload: { categoryId }
                })
            }
        }


    }, [isAddedSuccessfully])
    useEffect(() => {
        if (categoryId !== undefined & categoryId !== null) {
            dispatch({
                type: "FETCH_MENU_ITEM_REQUEST",
                payload: { categoryId }
            })
        }
    }, [categoryId])
    const getLocalDateTime = (mydate) => {
        var stillUtc = moment.utc(mydate).toDate();
        var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        return local
    }

    return (
        <Layout>
            <div>
                <div className="group_list">
                    <h4>Manage Menu</h4>
                </div>
                <div className="user_groups">
                    <div className="user_groups--button_n_search">
                        <div className="user_groups--button_n_search--button">
                            <h4>Menu</h4>
                            <button onClick={() => dispatch({ type: "ADD_MENU_ITEM_MODAL" })}>
                                Add <i className="bi bi-plus c-plus" />
                            </button>
                            <label htmlFor="pageSize" style={{ marginLeft: "10px", fontSize: '18px', fontFamily: "bold", color: "#3e445e", marginRight: "-10px" }}> Category</label>
                            <select style={{ width: "150px" }} name="pageSize" onClick={(e) => {
                                setCategoryId(e.target.value);
                                dispatch({ type: "CHANGE_CATEGORY_IS", payload: e.target.value })
                            }}>
                                {(activeCategoryList !== null & activeCategoryList !== false) ? activeCategoryList.map((item) => <option key={item.id} value={item.id}>{item.categoryName}</option>) : null}
                                {/* {activeCategoryList !== false && "fucking awesome"} */}

                            </select>
                            <label htmlFor="pageSize" style={{ marginLeft: "10px", fontSize: '18px', fontFamily: "bold", color: "#3e445e", marginRight: "-10px" }}> Per Page</label>
                            <select style={{ width: "80px" }} name="pageSize" onClick={(e) => setPostsPerPage(e.target.value)}>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="user_groups--button_n_search--search">
                            {/* <input type="text" placeholder="Search" /> */}
                        </div>
                    </div>
                    <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                    <div className="table-container">
                        <table className="table_x">
                            <thead>
                                <tr>
                                    <th className="category--id">S.N.</th>
                                    <th className="category--image">Image</th>
                                    <th className="category--name">Menu Name</th>
                                    <th className="category--description">Description</th>
                                    <th className="category--name">Added Date</th>

                                    <th className="category--addedBy">Price</th>
                                    <th className="category--addedBy">Status</th>
                                    <th className="category--actions">Actions</th>
                                </tr></thead>
                            <tbody>
                                {currentPosts !== undefined & currentPosts !== false && currentPosts.map((item, index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td><img src={item.imageUrl} /></td>
                                        <td>{item.itemName}</td>
                                        <td>{item.description}</td>
                                        <td>{getLocalDateTime(item.addedOn)}</td>
                                        <td>{item.price}</td>

                                        <td><span style={{ backgroundColor: "green", borderRadius: "4px", color: "white" }}>{item.isActive ? "Active" : "Not Active"}</span></td>

                                        <td>
                                            <Tippy content={<span>{item.isActive ? "De-activate" : "Activate"}</span>}>
                                                <span><BsCheckCircle onClick={() => {
                                                    setShowSmModal(true);
                                                    setEnableDisableItem(item)

                                                }} style={{ cursor: "pointer" }} /></span>
                                            </Tippy>
                                            <Tippy content="Edit">
                                                <span><AiFillEdit className="report-edit" style={{ cursor: "pointer" }} onClick={() => {
                                                    setUpdateMenuModal(true);
                                                    setEditingItem(item)
                                                }} /></span>
                                            </Tippy>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className={`${updateMenuModal ? "mymodal modal_activated" : "mymodal"}`}>
                            <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                                {editingItem !== null && <EditMenu item={editingItem} />}
                            </div>
                            <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                                <div className="task--rectangle__circle">
                                    <div className='circle'> <span onClick={() => {
                                        setUpdateMenuModal(!updateMenuModal);
                                        dispatch({
                                            type: "FETCH_MENU_ITEM_REQUEST",
                                            payload: { categoryId }
                                        })
                                    }}><BsX /></span><p>Edit</p></div>
                                </div>
                            </div>
                        </div>
                        {/* for Enable/Disable Category item  */}

                        <div className={`${showSmModal ? "delete--admin" : "delete--admin__hidden"}`} >
                            <div className="delete--admin--container" >
                                <div className="delete--admin--container__heading"></div>
                                <><div className="delete--admin--container__body">
                                    {enableDisableItem.isActive ? <h1>Are you sure to De-Activate this ??</h1> : <h1>Are you sure to Activate this ??</h1>}
                                    <div>{isEnabledUsers === "a" && <div className="deleting--something">  <p>Processing...</p></div>}</div>
                                    <div> {isEnabledUsers === "b" && <div className="deleting--something"> <TiTick className="success--icon" /> <p>Successful</p></div>}</div>
                                    <div> {isEnabledUsers === "c" && <div className="deleting--something"> <GiSkullCrossedBones className="success--icon" style={{ fontSize: "30px" }} /> <p>Try Again</p>

                                    </div>}</div>
                                </div>
                                    <div className="delete--admin--container__footer">
                                        <button onClick={() => setShowSmModal(!showSmModal)}>Cancel</button>
                                        {enableDisableItem.isActive ? <button onClick={() => {
                                            let id = enableDisableItem.id
                                            dispatch({
                                                type: "DEACTIVATE_MENU_ITEM_REQUEST",
                                                payload: {
                                                    id
                                                }
                                            })
                                        }}>Ok</button> :
                                            <button onClick={() => {
                                                let id = enableDisableItem.id
                                                dispatch({
                                                    type: "ACTIVATE_MENU_ITEM_REQUEST",
                                                    payload: {
                                                        id
                                                    }
                                                })
                                            }}>Ok</button>}
                                    </div></>

                            </div>
                        </div>
                        <div className="pagination">
                            {itemsList !== undefined && <p>Showing {indexOfFirstPost} to {indexOfLastPost > itemsList.length ? itemsList.length : indexOfLastPost} of {itemsList.length} entries</p>}
                            <nav aria-label="...">
                                <ul className="pagination">
                                    {currentPage === 1 ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                                    </li>}
                                    {pageNumbers.map((x) =>
                                        <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                                    )}
                                    {currentPage == pageNumbers.length ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                                    </li>}
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </Layout>
    )
}


export default connect(mapStateToProps, null)(Menu)
