import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import Footer from '../Z_Footer/Footer'
import { connect, useDispatch } from 'react-redux'
import { AiOutlineUsergroupAdd, AiOutlineUserSwitch } from 'react-icons/ai'
import { FiLayers, FiUser } from 'react-icons/fi'
import { BiUserCheck, BiUser } from 'react-icons/bi'
import { RiUserUnfollowLine, RiUserFollowLine } from 'react-icons/ri'
import { BsPersonPlusFill, BsCheckBox, BsTextIndentLeft } from 'react-icons/bs'
import { FaLayerGroup } from 'react-icons/fa'
// import { MdOutlineLayers } from 'react-icons/md'
import LoadingComponent from '../LoadingComponent'
import { Link } from 'react-router-dom'
const mapStateToProps = (store) => {
    const { dashboardData, isLoadingOnLogin } = store.mainState;
    return { dashboardData, isLoadingOnLogin }
}

function Dashboard({ dashboardData, isLoadingOnLogin }) {
    const dispatch = useDispatch();
    const [myTime,setMyTime] = useState(0)
    const [myTotalUser, setMyTotalUser] = useState(0);
    const [myInactiveUsers, setInactiveUsers] = useState(0);
    const [myActiveUsers, setMyActuveUsers] = useState(0);
    const [myMaleUsers, setMyMaleUsers] = useState(0);
    const [myFamaleUsers, setMyFamaleUsers] = useState(0);
    const [myVerifiedUsers, setMyVerifiedUsers] = useState(0);
    const [myTodayVerifiedUsers, setMyTodayVerifiedUsers] = useState(0);
    const [myReferredUsers, setMyReferredUsers] = useState(0);
    const [myTodayAdded, setMyTodayAdded] = useState(0);


    useEffect(() => {
        dispatch({
            type: "FETCH_DASHBOARD_DATA_REQUEST"
        })
    }, [])
    if (myTotalUser < dashboardData.totalOrdersToday) {
        setTimeout(() => {
            setMyTotalUser(myTotalUser + 1)
        }, 10)
    }
    if (myInactiveUsers < dashboardData.totalDeliveredSalesToday) {
        setTimeout(() => {
            setInactiveUsers(myInactiveUsers + 1)
        }, 10)
    }
    if (myActiveUsers < dashboardData.allTimeDeliveredOrders) {
        setTimeout(() => {
            setMyActuveUsers(myActiveUsers + 1)
        },10)
    }
    if (myMaleUsers < dashboardData.allTimeDeliveredSales) {
        setTimeout(() => {
            setMyMaleUsers(myMaleUsers + 1)
        }, 10)
    }
    if(myFamaleUsers < dashboardData.todayPendingOrders) {
        setTimeout(()=> {
            setMyFamaleUsers(myFamaleUsers + 1)
        },10)
    }
    // if(myVerifiedUsers < dashboardData.totalVerifiedUsers) {
    //     setTimeout(()=> {
    //         setMyVerifiedUsers(myVerifiedUsers + 1)
    //     },20)
    // }
    // if(myTodayVerifiedUsers < dashboardData.totalVerifiedToday) {
    //     setTimeout(()=> {
    //         setMyTodayVerifiedUsers(myTodayVerifiedUsers + 1)
    //     },20)
    // }
    // if(myReferredUsers < dashboardData.totalReferredUsers) {
    //     setTimeout(()=> {
    //         setMyReferredUsers(myReferredUsers + 1)
    //     },20)
    // }
    // if(myTodayAdded < dashboardData.totalUsersAddedToday) {
    //     setTimeout(()=> {
    //         setMyTodayAdded(myTodayAdded + 1)
    //     },20)
    // }
        useEffect(()=> {
        setInterval(()=> {
        setMyTime(()=> myTime + 1)
        },120000)
        dispatch({
            type: "FETCH_DASHBOARD_DATA_REQUEST"
        })

    },[myTime])

    return (
        <>
            <Layout>
                {/* <h1>{myTotalUser}</h1> */}
                <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                <h3 style={{ fontSize: "24px", margin: "10px 0" }}>Welcome To Dashboard</h3>
                <div className="dashboard" style={{ marginBottom: "20px", minHeight: "60vh" }}>
                    <div className="dashboard--headers">
                        <Link to='/order-manage' style={{ textDecoration: "none" }}>
                            <div className="dashboard--headers__item">
                                <div className="dashboard--item--description">
                                    <div className="item--container">
                                        <p>{dashboardData.totalOrdersToday}</p>
                                        <p>Today Orders</p>
                                    </div>
                                    <div className="icon--container">
                                        <FiLayers />
                                    </div>
                                </div>

                            </div>
                        </Link>

                        <div className="dashboard--headers__item">
                            <div className="dashboard--item--description">
                                <div className="item--container">
                                    <p>{dashboardData.totalDeliveredSalesToday}</p>
                                    <p>Today Sales</p>
                                </div>
                                <div className="icon--container">
                                    <BsCheckBox />
                                </div>
                            </div>
                        </div>
                        <Link to='/order-manage' style={{ textDecoration: "none" }}>
                            <div className="dashboard--headers__item">
                                <div className="dashboard--item--description">
                                    <div className="item--container">
                                        <p>{dashboardData.allTimeDeliveredOrders}</p>
                                        <p>All Time Orders</p>
                                    </div>
                                    <div className="icon--container">
                                        <FaLayerGroup />
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div className="dashboard--headers__item">
                            <div className="dashboard--item--description">
                                <div className="item--container">
                                    <p>{dashboardData.allTimeDeliveredSales} </p>
                                    <p>All Time Sales</p>
                                </div>
                                <div className="icon--container">
                                    <BsTextIndentLeft />
                                </div>
                            </div>
                        </div>
                        <div className="dashboard--headers__item">
                            <div className="dashboard--item--description">
                                <div className="item--container">
                                    <p>{dashboardData.todayPendingOrders} </p>
                                    <p>Today Pending Orders</p>
                                </div>
                                <div className="icon--container">
                                    <AiOutlineUserSwitch />
                                </div>
                            </div>
                        </div>
                        {/* <div className="dashboard--headers__item">
                            <div className="dashboard--item--description">
                                <div className="item--container">
                                    <p>{myFamaleUsers} </p>
                                    <p>Female Users</p>
                                </div>
                                <div className="icon--container">
                                    <BiUser />
                                </div>
                            </div>
                        </div>
                        <div className="dashboard--headers__item">
                            <div className="dashboard--item--description">
                                <div className="item--container">
                                    <p>{myVerifiedUsers}  </p>
                                    <p>Total Verified Users</p>
                                </div>
                                <div className="icon--container">
                                    <RiUserFollowLine />
                                </div>
                            </div>
                        </div>
                        <div className="dashboard--headers__item">
                            <div className="dashboard--item--description">
                                <div className="item--container">
                                    <p>{myTodayVerifiedUsers}  </p>
                                    <p>Today Verified Users</p>
                                </div>
                                <div className="icon--container">
                                    <FiUserCheck />
                                </div>
                            </div>
                        </div>
                        <div className="dashboard--headers__item">
                            <div className="dashboard--item--description">
                                <div className="item--container">
                                    <p>{myReferredUsers}</p>
                                    <p>Referred Users</p>
                                </div>
                                <div className="icon--container">
                                    <AiOutlineUserSwitch />
                                </div>
                            </div>
                        </div>
                       
                        <div className="dashboard--headers__item">
                            <div className="dashboard--item--description">
                                <div className="item--container">
                                    <p>{myTodayAdded}</p>
                                    <p>Today Added Users</p>
                                </div>
                                <div className="icon--container">
                                    <BsPersonPlusFill />
                                </div>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
                <Footer />
            </Layout>
        </>
    )
}

export default connect(mapStateToProps, null)(Dashboard)
