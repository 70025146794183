import { toast } from "react-toastify";
import { authenticateAdmin } from "../FrontendHelper";
import { ADD_CATEGORY_INPUT, ADMIN_AUTH_FAILURE, ADMIN_AUTH_REQUEST, ADMIN_AUTH_SUCCESS, ADMIN_CREDENCIAL_INPUT, TOOGLE_SIDEBAR } from "./actionType";

const initialState = {
    buttonText: "Submit",
    updateButtonText: "Update",
    isToogleSidebar: false,
    isLoadingOnLogin: false,
    isSuccessOnLogin: false,
    isEnabledUsers: "",
    isAddedSuccessfully: "",
    adminCredencial: {
        userName: "",
        password: ""
    },
    dashboardData: {},
    adminUsersList: {},
    searchUsersList: {},
    couponData: {},
    restaurantData: {},
    privacyData: "",
    termsAndConditionData: "",
    categoryList: {},
    categoryListDropdown: [],
    menuList: {},
        orderList:{},
    orderDetails: {},
    adminProfileData: {},
    notificationList: {},
    // MODAL
    isAddCouponModal: false,
    isAddReataurantModal: false,
    isAddAdminRegisterModal: false,
    isAddCategoryModal: false,
    isAddMenuItemModal: false,
    isServiceRateModal:false,


}

const orkReducer = (state = initialState, action) => {
    // sidebar toggler
    if (action.type === TOOGLE_SIDEBAR) {
        return { ...state, isToogleSidebar: !state.isToogleSidebar }
    }
    // fetch Admin dashboard Data
    if (action.type === "FETCH_DASHBOARD_DATA_REQUEST") {
        return { ...state, isLoadingOnLogin: true }
    }
    if (action.type === "FETCH_DASHBOARD_DATA_SUCCESS") {
        return { ...state, dashboardData: action.payload, isLoadingOnLogin: false }
    }
    if (action.type === "FETCH_DASHBOARD_DATA_FAILURE") {
        toast.error("Admin Autorization Denied !!!");
        return { ...state, isLoadingOnLogin: false }
    }
    // Admin Controller Section
    // Fetch Admin List
    if (action.type === "FETCH_ADMIN_USERS_REQUEST") {
        return { ...state, isLoadingOnLogin: "true", }
    }
    if (action.type === "FETCH_ADMIN_USERS_SUCCESS") {
        return { ...state, adminUsersList: action.payload, isLoadingOnLogin: false,buttonText: "Submit" }
    }
    if (action.type === "FETCH_ADMIN_USERS_FAILURE") {
        toast.error("Sorry!!! Couldnot fetch Admin list")
        return { ...state, buttonText: "Submit", isLoadingOnLogin: false}
    }
    // Fetch Admin profile
    if (action.type === "FETCH_ADMIN_USERS_PROFILE_REQUEST") {
        return { ...state, isLoadingOnLogin: "true", }
    }
    if (action.type === "FETCH_ADMIN_USERS_PROFILE_SUCCESS") {
        return { ...state, adminProfileData: action.payload, isLoadingOnLogin: false,buttonText: "Submit" }
    }
    if (action.type === "FETCH_ADMIN_USERS_PROFILE_FAILURE") {
        toast.error("Something gone wrong. Try Again Later !!!")
        return { ...state, buttonText: "Submit", isLoadingOnLogin: false}
    }
        // Add admin 
    if (action.type === "POST_ADMIN_USERS_REQUEST") {
        return { ...state, buttonText: "Submitting" }
    }
    if (action.type === "POST_ADMIN_USERS_SUCCESS") {
        toast.success("New admin added Successfully !!!")
        return { ...state, buttonText: "Submit" }
    }
    if (action.type === "POST_ADMIN_USERS_FAILURE") {
        toast.error(action.payload.data.message);
        return { ...state, buttonText: "Submit" }
    }
        // update admin
    if (action.type === "UPDATE_ADMIN_USERS_REQUEST") {
        return { ...state, updateButtonText: "Updating" }
    }
    if (action.type === "UPDATE_ADMIN_USERS_SUCCESS") {
        toast.success("Admin Updated Successfully !!!")
        return { ...state, updateButtonText: "Update" }
    }
    if (action.type === "UPDATE_ADMIN_USERS_FAILURE") {
        if (action.payload.data === undefined) {
            toast.error("Couldn't Update Admin !!!")
        } else {
            toast.error(action.payload.data.message)
        }
        return { ...state, updateButtonText: "Update" }
    }

    // post data to authorize
    if (action.type === ADMIN_AUTH_REQUEST) {
        return { ...state, isLoadingOnLogin: true }
    }
    if (action.type === ADMIN_AUTH_SUCCESS) {
        authenticateAdmin(action.payload, () => {
            console.log("the data is saved in locaStorage")
        })
        return { ...state, isSuccessOnLogin: true, isLoadingOnLogin: false }
    }
    if (action.type === ADMIN_AUTH_FAILURE) {
        if (action.payload.data === undefined) {
            toast.error("Invalid Login Credentials !!!");
        } else {
            toast.error(action.payload.data.message)
        }
        return { ...state, isLoadingOnLogin: false }
    }

    // Log out
    if (action.type === "LOG_OUT_REQUEST") {
        return { ...state, isSuccessOnLogin: false }
    }
    // input form data 
    if (action.type === ADMIN_CREDENCIAL_INPUT) {
        return { ...state, adminCredencial: action.payload.values }
    }
    if (action.type === ADD_CATEGORY_INPUT) {
        return { ...state, isPostingCategory: action.payload.values }
    }
    // // fetch Privacy data
    if (action.type === "FETCH_PRIVACY_REQUEST") {
        return { ...state, isLoadingOnLogin: true }
    }
    if (action.type === "FETCH_PRIVACY_SUCCESS") {
        return { ...state, privacyData: action.payload, isLoadingOnLogin: false }
    }
    if (action.type === "FETCH_PRIVACY_FAILURE") {
        toast.error("Sorry!!! Network Error.")
        return { ...state, isLoadingOnLogin: false }
    }
    // fetch terms and conditions
    if (action.type === "FETCH_TERMS_CONDITION_REQUEST") {
        return { ...state, isLoadingOnLogin: true }
    }
    if (action.type === "FETCH_TERMS_CONDITION_SUCCESS") {
        return { ...state, termsAndConditionData: action.payload, isLoadingOnLogin: false }
    }
    if (action.type === "FETCH_TERMS_CONDITION_FAILURE") {
        toast.error("Sorry!!! Network Error.")
        return { ...state, isLoadingOnLogin: false }
    }
    // posting privacy and policy
    if (action.type === "POST_PRIVACY_REQUEST") {
        return { ...state, updateButtonText: "Updating" }
    }
    if (action.type === "POST_PRIVACY_SUCCESS") {
        toast.success("Privacy and Policy updated successfully !!!")
        return { ...state, updateButtonText: "Update" }
    }
    if (action.type === "POST_PRIVACY_FAILURE") {
        toast.error("Couldnot update !!!")
        return { ...state, updateButtonText: "Update" }
    }
    // posting terms and condition
    if (action.type === "POST_TERMS_N_CONDITION_REQUEST") {
        return { ...state, updateButtonText: "Updating" }
    }
    if (action.type === "POST_TERMS_N_CONDITION_SUCCESS") {
        toast.success("Terms and condition updated successfully !!!")
        return { ...state, updateButtonText: "Update" }
    }
    if (action.type === "POST_TERMS_N_CONDITION_FAILURE") {
        toast.error("Couldnot update. Try again.")
        return { ...state, updateButtonText: "Update" }
    }
    // Add mass notification 
    if (action.type === "ADD_MASS_NOTIFICATION_REQUEST") {
        return { ...state, buttonText: "Submitting" }
    }
    if (action.type === "ADD_MASS_NOTIFICATION_SUCCESS") {
        toast.success(action.payload.message)
        return { ...state, buttonText: "Submit" }
    }
    if (action.type === "ADD_MASS_NOTIFICATION_FAILURE") {
        if (action.payload !== undefined) {
            toast.error(action.payload.data.message)
        } else {
            toast.error("Network Error. Try Again!!!")
        }
        return { ...state, buttonText: "Submit" }
    }

    // fetch userslist data
    if (action.type === "FETCH_USERS_LIST_REQUEST") {
        console.log("The search user list request send")
        return { ...state, isLoadingOnLogin: true }
    }
    if (action.type === "FETCH_USERS_LIST_SUCCESS") {
        return { ...state, searchUsersList: action.payload, isLoadingOnLogin: false, isEnabledUsers: "" }
    }
    if (action.type === "FETCH_USERS_LIST_FAILURE") {
        toast.error("Sorry!!! Network Error.")
        return { ...state, isLoadingOnLogin: false }
    }
    // fetch userslist by id for details data
    if (action.type === "FETCH_USER_BY_ID_REQUEST") {
        return { ...state }
    }
    if (action.type === "FETCH_USER_BY_ID_SUCCESS") {
        return { ...state, userDetails: action.payload }
    }
    if (action.type === "FETCH_USER_BY_ID_FAILURE") {
        toast.error("Sorry!!! Network Error.")
        return { ...state }
    }
    // // Enable users
    if (action.type === "ENABLE_USERS_REQUEST") {
        return { ...state, isEnabledUsers: "a" }
    }
    if (action.type === "ENABLE_USERS_SUCCESS") {
        toast.success(action.payload.message)
        return { ...state, isEnabledUsers: "b" }
    }
    if (action.type === "ENABLE_USERS_FAILURE") {
        toast.error(action.payload.data.message)
        return { ...state, isEnabledUsers: "" }
    }
    // // Disable users
    if (action.type === "DISABLE_USERS_REQUEST") {
        return { ...state, isEnabledUsers: "a" }
    }
    if (action.type === "DISABLE_USERS_SUCCESS") {
        toast.success(action.payload.message)
        return { ...state, isEnabledUsers: "b" }
    }
    if (action.type === "DISABLE_USERS_FAILURE") {
        toast.error(action.payload.data.message)
        return { ...state, isEnabledUsers: "" }
    }
      // fetch category list
    if (action.type === "FETCH_CATEGORY_LIST_REQUEST") {
        return { ...state, isLoadingOnLogin: true }
    }
    if (action.type === "FETCH_CATEGORY_LIST_SUCCESS") {
        return { ...state, categoryList: action.payload, isLoadingOnLogin: false, isEnabledUsers: "", buttonText: "Submit", updateButtonText: "Update" }
    }
    if (action.type === "FETCH_CATEGORY_LIST_FAILURE") {
        toast.error("Sorry!!! Network Error.")
        return { ...state, isLoadingOnLogin: false, isEnabledUsers: "", buttonText: "Submit", updateButtonText: "Update" }
    }
      // fetch category list for add menu dropdown
    if (action.type === "FETCH_CATEGORY_LIST_DROPDOWN_REQUEST") {
        return { ...state, isLoadingOnLogin: true }
    }
    if (action.type === "FETCH_CATEGORY_LIST_DROPDOWN_SUCCESS") {
        return { ...state, categoryListDropdown: action.payload, isLoadingOnLogin: false, isEnabledUsers: "", buttonText: "Submit", updateButtonText: "Update" }
    }
    if (action.type === "FETCH_CATEGORY_LIST_DROPDOWN_FAILURE") {
        toast.error("Sorry!!! Network Error.")
        return { ...state, isLoadingOnLogin: false, isEnabledUsers: "", buttonText: "Submit", updateButtonText: "Update" }
    }
    // post data to add category
    if (action.type === "POST_CATEGORY_REQUEST") {
        return { ...state, buttonText: "Submitting" }
    }
    if (action.type === "POST_CATEGORY_SUCCESS") {
        toast.success("Category added Successfully !!")
        return { ...state, buttonText: "Submitted" }
    }
    if (action.type === "POST_CATEGORY_FAILURE") {
        toast.error(action.payload.data.message)
        return { ...state, buttonText: "Submit" }
    }
    // Updating Category
    if (action.type === "PUT_CATEGORY_REQUEST") {
        return { ...state, updateButtonText: "Updating" }
    }
    if (action.type === "PUT_CATEGORY_SUCCESS") {
        toast.success("Category updated Successfully !!")
        return { ...state, updateButtonText: "Update" }
    }
    if (action.type === "PUT_CATEGORY_FAILURE") {
        toast.error(action.payload.data.message)
        return { ...state, updateButtonText: "Update" }
    }
    // Enable Disable category
    if (action.type === "ENABLE_CATEGORY_REQUEST") {
        return { ...state, isEnabledUsers: "a" }
    }
    if (action.type === "ENABLE_CATEGORY_SUCCESS") {
        toast.success(action.payload.message)
        return { ...state, isEnabledUsers: "b" }
    }
    if (action.type === "ENABLE_CATEGORY_FAILURE") {
        toast.error(action.payload.data.message)
        return { ...state,  isEnabledUsers: "" }
    }
    // Disable Disable category
    if (action.type === "DISABLE_CATEGORY_REQUEST") {
        return { ...state, isEnabledUsers: "a" }
    }
    if (action.type === "DISABLE_CATEGORY_SUCCESS") {
        toast.success(action.payload.message)
        return { ...state, isEnabledUsers: "b" }
    }
    if (action.type === "DISABLE_CATEGORY_FAILURE") {
        toast.error(action.payload.data.message)
        return { ...state,  isEnabledUsers: "" }
    }
          // fetch Menu list
    if (action.type === "FETCH_MENU_ITEM_REQUEST") {
        return { ...state, isLoadingOnLogin: true }
    }
    if (action.type === "FETCH_MENU_ITEM_SUCCESS") {
        return { ...state, menuList: action.payload, isLoadingOnLogin: false, isEnabledUsers: "", buttonText: "Submit", updateButtonText: "Update" ,isAddedSuccessfully: ""}
    }
    if (action.type === "FETCH_MENU_ITEM_FAILURE") {
        toast.error("Sorry!!! Network Error.")
        return { ...state, isLoadingOnLogin: false, isEnabledUsers: "", buttonText: "Submit", updateButtonText: "Update" }
    }
          // Add Menu items
    if (action.type === "POST_MENU_ITEM_REQUEST") {
        return { ...state, buttonText: "Submitting" }
    }
    if (action.type === "POST_MENU_ITEM_SUCCESS") {
        toast.success(action.payload.message)
        return { ...state, buttonText: "Submit",isAddedSuccessfully: "yes" }
    }
    if (action.type === "POST_MENU_ITEM_FAILURE") {
        toast.error(action.payload.data.message)
        return { ...state, buttonText: "Submit" }
    }
    // Update menu item
    if (action.type === "UPDATE_MENU_ITEM_REQUEST") {
        return { ...state, updateButtonText: "Updatting" }
    }
    if (action.type === "UPDATE_MENU_ITEM_SUCCESS") {
        toast.success(action.payload.message)
        return { ...state, updateButtonText: "Update",isAddedSuccessfully: "yes" }
    }
    if (action.type === "UPDATE_MENU_ITEM_FAILURE") {
        toast.error(action.payload.data.message)
        return { ...state, updateButtonText: "Update" }
    }
    // Active menu item
    if (action.type === "ACTIVATE_MENU_ITEM_REQUEST") {
        return { ...state, isEnabledUsers: "a" }
    }
    if (action.type === "ACTIVATE_MENU_ITEM_SUCCESS") {
        toast.success(action.payload.message)
        return { ...state, isEnabledUsers: "b" }
    }
    if (action.type === "ACTIVATE_MENU_ITEM_FAILURE") {
        toast.error(action.payload.data.message)
        return { ...state, isEnabledUsers:"" }
    }
    // de-Active menu item
    if (action.type === "DEACTIVATE_MENU_ITEM_REQUEST") {
        return { ...state, isEnabledUsers: "a" }
    }
    if (action.type === "DEACTIVATE_MENU_ITEM_SUCCESS") {
        toast.success(action.payload.message)
        return { ...state, isEnabledUsers: "b" }
    }
    if (action.type === "DEACTIVATE_MENU_ITEM_FAILURE") {
        toast.error(action.payload.data.message)
        return { ...state, isEnabledUsers: "" }
    }
    // Get order List
    if (action.type === "FETCH_ORDER_LIST_REQUEST") {
        return { ...state, isLoadingOnLogin: true }
    }
    if (action.type === "FETCH_ORDER_LIST_SUCCESS") {
        return { ...state, isLoadingOnLogin: false,orderList:action.payload,isEnabledUsers: "" }
    }
    if (action.type === "FETCH_ORDER_LIST_FAILURE") {
        toast.error("Network Error !!!. Try Again.")
        return { ...state, isLoadingOnLogin: false }
    }
    // Get order details by id
    if (action.type === "ORDER_DETAILS_REQUEST") {
        return { ...state, isLoadingOnLogin: true }
    }
    if (action.type === "ORDER_DETAILS_SUCCESS") {
        return { ...state, isLoadingOnLogin: false,orderDetails:action.payload }
    }
    if (action.type === "ORDER_DETAILS_FAILURE") {
        toast.error("Network Error !!!. Try Again.");
        return { ...state, isLoadingOnLogin: false }
    }
    // Accept order 
    if (action.type === "ACCEPT_ORDER_REQUEST") {
        return { ...state, isEnabledUsers: "a" }
    }
    if (action.type === "ACCEPT_ORDER_SUCCESS") {
        toast.success("Great !!! Successfully Accepted Order.")
        return { ...state, isEnabledUsers: "b" }
    }
    if (action.type === "ACCEPT_ORDER_FAILURE") {
        toast.error(action.payload.data.message);
        return { ...state, isEnabledUsers: "" }
    }
    // order ready
    if (action.type === "SET_ORDER_READY_REQUEST") {
        return { ...state, isEnabledUsers: "a" }
    }
    if (action.type === "SET_ORDER_READY_SUCCESS") {
        toast.success("Great !!! Order Status Changed to ORDER_READY.")
        return { ...state, isEnabledUsers: "b" }
    }
    if (action.type === "SET_ORDER_READY_FAILURE") {
        toast.error(action.payload.data.message);
        return { ...state, isEnabledUsers: "" }
    }
    // set opening and closing time of restaurnat
    if (action.type === "SET_CLOSING_OPENING_TIME_REQUEST") {
        return { ...state, buttonText: "Submiting",isEnabledUsers: "a" }
    }
    if (action.type === "SET_CLOSING_OPENING_TIME_SUCCESS") {
        toast.success("Great !!! Successfully Set Opening And Closing Time.")
        return { ...state, buttonText: "Submitted",isEnabledUsers: "b" }
    }
    if (action.type === "SET_CLOSING_OPENING_TIME_FAILURE") {
        toast.error(action.payload.data.message);
        return { ...state, buttonText: "Submit",isEnabledUsers: "" }
    }
    // open restaurnat
    if (action.type === "OPEN_RESTAURANT_REQUEST") {
        return { ...state, buttonText: "Submiting",isEnabledUsers: "a" }
    }
    if (action.type === "OPEN_RESTAURANT_SUCCESS") {
        toast.success("Great. Resturant is Opened !!!.")
        return { ...state, buttonText: "Submitted",isEnabledUsers: "b" }
    }
    if (action.type === "OPEN_RESTAURANT_FAILURE") {
        toast.error(action.payload.data.message);
        return { ...state, buttonText: "Submit",isEnabledUsers: "" }
    }
    // closing time of restaurnat
    if (action.type === "CLOSE_RESTAURANT_REQUEST") {
        return { ...state, buttonText: "Submiting",isEnabledUsers: "a" }
    }
    if (action.type === "CLOSE_RESTAURANT_SUCCESS") {
        toast.success("Great. Restaurant is Closed !!!.")
        return { ...state, buttonText: "Submitted",isEnabledUsers: "b" }
    }
    if (action.type === "CLOSE_RESTAURANT_FAILURE") {
        toast.error(action.payload.data.message);
        return { ...state, buttonText: "Submit",isEnabledUsers: "" }
    }
    // fetch notification list
    if (action.type === "FETCH_NOTIFICATION_REQUEST") {
        return { ...state,isLoadingOnLogin:true}
    }
    if (action.type === "FETCH_NOTIFICATION_SUCCESS") {
        return { ...state, isLoadingOnLogin:false,notificationList:action.payload }
    }
    if (action.type === "FETCH_NOTIFICATION_FAILURE") {
        if(action.payload !== undefined) {
            toast.error(action.payload.data.message);
        } else {
            toast.error("Internal Server Error !!! Try Again Later.")
        }
        
        return { ...state, isLoadingOnLogin:false}
    }
    // make seen notification list
    if (action.type === "MAKE_SEEN_NOTIFICATION_REQUEST") {
        return { ...state,isEnabledUsers: "a"}
    }
    if (action.type === "MAKE_SEEN_NOTIFICATION_SUCCESS") {
        return { ...state, isEnabledUsers:"seen" }
    }
    if (action.type === "MAKE_SEEN_NOTIFICATION_FAILURE") {
        if(action.payload !== undefined) {
            toast.error(action.payload.data.message);
        } else {
            toast.error("Internal Server Error !!! Try Again Later.")
        }
        
        return { ...state, isEnabledUsers: ""}
    }


    // handle modals
    if (action.type === "ADD_COUPON_MODAL") {
        return { ...state, isAddCouponModal: !state.isAddCouponModal }
    }
    if (action.type === "ADD_RESTAURANT_MODAL") {
        return { ...state, isAddReataurantModal: !state.isAddReataurantModal }
    }
    if (action.type === "ADD_ADMIN_REGISTER_MODAL") {
        return { ...state, isAddAdminRegisterModal: !state.isAddAdminRegisterModal }
    }
    if (action.type === "ADD_CATEGORY_MODAL") {
        return { ...state, isAddCategoryModal: !state.isAddCategoryModal }
    }
    if (action.type === "ADD_MENU_ITEM_MODAL") {
        return { ...state, isAddMenuItemModal: !state.isAddMenuItemModal }
    }
        if (action.type === "OPEN_SERVICE_RATE_MODAL") {
        return { ...state, isServiceRateModal: !state.isServiceRateModal }
    }

    return state;
}

export default orkReducer

