import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // ES6

import Layout from "../../Layout/Layout";
import Footer from "../Z_Footer/Footer";
import LoadingComponent from "../LoadingComponent";

const mapStateToProps = (store) => {
  const { updateButtonText, privacyData, isLoadingOnLogin } = store.mainState;
  return { updateButtonText, privacyData, isLoadingOnLogin };
};

function Privacy({ updateButtonText, privacyData, isLoadingOnLogin }) {
  const dispatch = useDispatch();
  const [privacyText, setPrivacyText] = useState("");

  useEffect(() => {
    dispatch({ type: "FETCH_PRIVACY_REQUEST" });
  }, []);
  useEffect(() => {
    setPrivacyText(privacyData);
  }, [privacyData]);

  return (
    <Layout>
      <p className="privacy-loading--onFetching">
        {isLoadingOnLogin && <LoadingComponent />}
      </p>
      <div className="privacy--container">
        <div className="terms_n_condition--heading">
          Foocery Privacy & Policy
        </div>
        <form>
          <ReactQuill theme="snow" value={privacyText} readOnly="true" />
        </form>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(Privacy);
