import React, { useState, useEffect } from 'react'
import { connect,useDispatch } from 'react-redux';
import {BsArrowRepeat} from 'react-icons/bs'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6

import Layout from '../../Layout/Layout'
import Footer from '../Z_Footer/Footer'
import LoadingComponent from '../LoadingComponent';

const mapStateToProps = store=> {
    const {termsAndConditionData,isLoadingOnLogin} = store.mainState;
    return {termsAndConditionData,isLoadingOnLogin}
}

function Terms_nCondition({termsAndConditionData,isLoadingOnLogin}) {
    const dispatch = useDispatch();
    const [privacyText, setPrivacyText] = useState("");

    useEffect(()=> {
        dispatch({
            type: "FETCH_TERMS_CONDITION_REQUEST"
        })
    },[])
    useEffect(()=> {
       setPrivacyText(termsAndConditionData)
    },[termsAndConditionData])

    return (
        <Layout>
           <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
            <div className="privacy--container">
            <div className="terms_n_condition--heading">
                Terms And Conditions
            </div>
               <form>
                 <ReactQuill theme="snow" value={privacyText} readOnly="true"  />
               </form>
                <Footer />
            </div>

        </Layout>
    )
}

export default connect(mapStateToProps,null) (Terms_nCondition)
