import React, { useState, useRef, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import guruGLogo from "../../Images/logo-sm.png";
import foocery from "../../Images/foocery-logo.png";
import {
  AiFillCheckCircle,
  AiFillDollarCircle,
  AiFillCloseCircle,
  AiFillTrademarkCircle,
  AiFillCodepenCircle,
} from "react-icons/ai";
// import AssignOrderToD from './AssignOrderToD'
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { BsX } from "react-icons/bs";
import LoadingComponent from "./../LoadingComponent";
import moment from "moment";

const mapStateToProps = (store) => {
  const { orderDetails, isEnabledUsers, isToogleSidebar, isLoadingOnLogin } =
    store.mainState;
  return { orderDetails, isEnabledUsers, isToogleSidebar, isLoadingOnLogin };
};

function OrderDetails({
  id,
  orderDetails,
  isEnabledUsers,
  isToogleSidebar,
  isLoadingOnLogin,
}) {
  const dispatch = useDispatch();
  const [reasonForEnableDisable, setReasonForEnableDisable] = useState("");
  // Approve Order
  const [showApproveOrderModal, setShowApproveOrderModal] = useState(false);
  const [approveOrderId, setApproveOrderId] = useState(null);
  // order ready
  const [showOrderReadyModal, setShowOrderReadyModal] = useState(false);
  const [orderReadyId, setOrderReadyId] = useState(null);

  useEffect(() => {
    dispatch({
      type: "ORDER_DETAILS_REQUEST",
      payload: id,
    });
  }, [id]);
  useEffect(() => {
    if (isEnabledUsers === "b") {
      setTimeout(() => {
        dispatch({
          type: "ORDER_DETAILS_REQUEST",
          payload: id,
        });
        setShowApproveOrderModal(false);
        setShowOrderReadyModal(false);
        setReasonForEnableDisable("");
      }, 1000);
    }
  }, [isEnabledUsers]);
  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };
  return (
    <div className="orderDetailsShowing--container">
      <div className="Modal--heading">
        <span style={{ color: "#3e445e" }}>Order Details</span>
      </div>
      <p className="privacy-loading--onFetching">
        {isLoadingOnLogin && <LoadingComponent />}
      </p>
      {/* <div className="modal--print_export">
        <div className="modal--print_export--button">
          <button>Print</button>
          <button>Export As PDF</button>
        </div>
      </div>
      <hr /> */}
      <div className="modal-orderDetails--heading">
        <div className="modal-orderDetails--heading__logo">
          <img
            style={{ width: "20rem", height: "auto" }}
            src={foocery}
            alt="foocery Logo"
          />
        </div>
        <div className="modal-orderDetails--heading__description">
          <p>Foocery</p>
          {/* <p>Maitidevi, Kathmandu, Nepal</p> */}
          <p>1300287637, 0425 327 800</p>
          <p>contact@foocery.com</p>
        </div>
      </div>
      <hr />
      <div className="modal-orderDetails--body">
        {orderDetails.deliveryInfoDto !== undefined && (
          <>
            <div className="modal-orderDetails--body__orderFrom">
              <p>Order From :</p>
              <p>{orderDetails.customerInfo.fullName}</p>
              <p>{orderDetails.customerInfo.location}</p>
              <p>
                {orderDetails.customerInfo.phoneNumber},
                {orderDetails.deliveryInfoDto.deliveryAlternateContactNo}
              </p>
            </div>
            <div className="modal-orderDetails--body__orderStatus">
              <p>
                Order Status: <span>{orderDetails.orderStatus}</span>
              </p>
              <p>
                Payment Method: <span>{orderDetails.paymentMethod}</span>
              </p>
              <p>
                Payment Status: <span>{orderDetails.paymentStatus}</span>
              </p>
            </div>
            <div className="modal-orderDetails--body__orderid">
              <p>{orderDetails.customerOrderId}</p>
              <p>
                Date of Order:{" "}
                {/* {orderDetails.scheduledTime !== "null"
                  ? getLocalDateTime(orderDetails.scheduledTime)
                  : "Not Available"} */}
                {orderDetails?.checkOutDateTime
                  ? getLocalDateTime(orderDetails.checkOutDateTime)
                  : "Not Available"}
              </p>
              <p>
                Date of Delivery:{" "}
                {orderDetails.checkOutDateTime !== "null"
                  ? getLocalDateTime(orderDetails.checkOutDateTime)
                  : "ASAP"}
              </p>
            </div>
          </>
        )}
      </div>
      <hr />
      <div className="modal-orderDetails--footer">
        <div className="modal-orderDetails--footer__orderTo">
          <table>
            <thead>
              <tr>
                <th className="orderDetail--sn">S.N.</th>
                <th className="orderDetail--itemName">Item Name</th>
                <th className="orderDetail--restaurant">Restaurant Name</th>
                <th className="orderDetail--note">Note</th>
                <th className="orderDetail--quantity">Quantity</th>
                <th className="orderDetail--price">Price(AUD.)</th>
                <th className="orderDetail--total">Total(AUD.)</th>
              </tr>
            </thead>
            <tbody>
              {orderDetails.cartData !== undefined &&
                orderDetails.cartData.cartItemDto.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.itemName}</td>
                    <td>{item.restaurantName}</td>
                    <td>{item.note}</td>
                    <td>{item.quantity}</td>
                    <td>{item.price}</td>
                    <td>{item.cartItemTotal}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* ac05088a-7d5e-4b7b-b5bf-73687fbf1510 */}
      <div className="orderDetails--Footer">
        <div className="button--controll">
          {orderDetails.orderStatus === "ORDER_CONFIRMED" && (
            <div className="button--controll--facebook">
              <p>
                <AiFillCodepenCircle
                  className="myPerfect--icon"
                  onClick={() => {
                    setShowApproveOrderModal(true);
                    setApproveOrderId(orderDetails.customerOrderId);
                  }}
                />
                <span>Approve_Order</span>
              </p>
            </div>
          )}
          {orderDetails.orderStatus === "PROCESSING" && (
            <>
              <div
                className="button--controll--facebook"
                onClick={() => {
                  setShowOrderReadyModal(true);
                  setOrderReadyId(orderDetails.customerOrderId);
                }}
              >
                <p>
                  <AiFillTrademarkCircle className="myPerfect--icon" />
                  <span>Order_Ready</span>
                </p>
              </div>
            </>
          )}
        </div>
        <div className="totalSection">
          {orderDetails.actualPaymentData !== undefined && (
            <div className="totalSection-container">
              {orderDetails.actualPaymentData.isCouponCodeApplied && (
                <p>
                  Coupon Code Discount{" "}
                  <span>
                    AUD. {orderDetails.actualPaymentData.couponDiscountAmount}
                  </span>
                </p>
              )}
              {orderDetails.actualPaymentData.isCouponCodeApplied && (
                <p>
                  CouponCode:
                  <span
                    style={{
                      backgroundColor: "purple",
                      color: "white",
                      padding: "3px 10px",
                      borderRadius: "5px",
                    }}
                  >
                    {orderDetails.actualPaymentData.couponCode}
                  </span>
                </p>
              )}
              {orderDetails.actualPaymentData.isCoinUsed && (
                <p>
                  Coin Used Discount{" "}
                  <span>
                    AUD. {orderDetails.actualPaymentData.coinEquivalentAmount}
                  </span>
                </p>
              )}
              <p>
                Sub-Total{" "}
                <span>AUD. {orderDetails.actualPaymentData.cartSubTotal}</span>
              </p>
              <div className="hrline"></div>
              <p>
                Service Charge{" "}
                <span>AUD. {orderDetails.actualPaymentData.serviceCharge}</span>
              </p>
              {orderDetails.actualPaymentData.deliveryCharge !== null && (
                <p>
                  Delivery Charge{" "}
                  <span>
                    AUD. {orderDetails.actualPaymentData.deliveryCharge}
                  </span>
                </p>
              )}
              <p>
                Tax Amount{" "}
                <span>AUD. {orderDetails.actualPaymentData.taxAmount}</span>
              </p>
              <div className="hrline"></div>
              <p>
                Grand Total{" "}
                <span>AUD. {orderDetails.actualPaymentData.grandTotal}</span>
              </p>
            </div>
          )}
        </div>
      </div>
      {/* Approve Order */}
      <div
        className={`${
          showApproveOrderModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h1>Sure to Approve this Order ??</h1>{" "}
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
              <div className="reason--enableDisable">
                <label>Confirmation Text : </label>
                <textarea
                  type="text"
                  required
                  placeholder="Confirmation Text"
                  value={reasonForEnableDisable}
                  onChange={(e) => setReasonForEnableDisable(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button
                onClick={() => setShowApproveOrderModal(!showApproveOrderModal)}
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "ACCEPT_ORDER_REQUEST",
                    payload: {
                      id: approveOrderId,
                      data: {
                        orderDecision: "APPROVE",
                        confirmText: reasonForEnableDisable,
                      },
                    },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      {/* order Ready */}
      <div
        className={`${
          showOrderReadyModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h1>Sure To Change Status To ORDER_READY ??</h1>{" "}
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button
                onClick={() => setShowOrderReadyModal(!showOrderReadyModal)}
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "SET_ORDER_READY_REQUEST",
                    payload: {
                      id: orderReadyId,
                      data: {
                        confirmText: "Yes",
                      },
                    },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      {orderDetails.deliveryAssignedTo !== null && (
        <>
          <h1 style={{ margin: "20px 20px 5px 20px" }}>Delivery Info :</h1>
          {orderDetails.deliveryInfoDto !== undefined && (
            <div className="delivery--info">
              <div className="delivery--info__firstContainer">
                <p>
                  Assinged To: <span>{orderDetails.deliveryAssignedTo}</span>
                </p>
                <p>
                  Delivery PickedUp Time:{" "}
                  <span>
                    {orderDetails.deliveryPickedUpTime !== "null"
                      ? getLocalDateTime(orderDetails.deliveryPickedUpTime)
                      : "Not Available"}
                  </span>
                </p>
                <p>
                  Delivery Reached Time:{" "}
                  <span>
                    {orderDetails.deliveredTime !== "null"
                      ? getLocalDateTime(orderDetails.deliveryReachedTime)
                      : "Not Available"}
                  </span>
                </p>
                <p>
                  Delivered Time:{" "}
                  <span>
                    {orderDetails.deliveryReachedTime !== "null"
                      ? getLocalDateTime(orderDetails.deliveredTime)
                      : "Not Available"}
                  </span>
                </p>
                <p>
                  Delivery Status: <span>{orderDetails.deliveryStatus}</span>
                </p>
                <p>
                  Driver Note:{" "}
                  <span style={{ color: "green" }}>
                    {orderDetails.driverNote}
                  </span>
                </p>
              </div>
              <div className="delivery--info__secondContainer">
                <div className="delivery--info__secondContainer--text">
                  <p>
                    Delivery Address:{" "}
                    <span>{orderDetails.deliveryInfoDto.deliveryAddress}</span>
                  </p>
                  <p>
                    Contact Person:{" "}
                    <span>{orderDetails.deliveryInfoDto.deliveryFullName}</span>
                  </p>
                  <p>
                    Contact Number:{" "}
                    <span>
                      {orderDetails.deliveryInfoDto.deliveryContactNo},
                      {orderDetails.deliveryInfoDto.deliveryAlternateContactNo}
                    </span>
                  </p>
                  <p>
                    Scheduled Time:{" "}
                    <span>
                      {orderDetails.deliveryInfoDto.scheduledTime !== null
                        ? getLocalDateTime(
                            orderDetails.deliveryInfoDto.scheduledTime
                          )
                        : "Not Scheduled"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default connect(mapStateToProps, null)(OrderDetails);
