import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import { AiFillEdit } from 'react-icons/ai'
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { BsCheckCircle } from 'react-icons/bs'
import { connect, useDispatch } from 'react-redux'
import Footer from '../Z_Footer/Footer'
import { GiSkullCrossedBones } from 'react-icons/gi'
import { TiTick } from 'react-icons/ti'
import { BsX } from 'react-icons/bs'
import LoadingComponent from '../LoadingComponent'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import moment from 'moment'


const mapStateToProps = store => {
    const { categoryList, isEnabledUsers, isLoadingOnLogin, isToogleSidebar ,notificationList} = store.mainState
    return { categoryList, isEnabledUsers, isLoadingOnLogin, isToogleSidebar ,notificationList}
}

function Notification({ categoryList, isEnabledUsers, isLoadingOnLogin, isToogleSidebar ,notificationList}) {
    // Enable Disable category
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [showSmModal, setShowSmModal] = useState(false);
    const [enableDisableItem, setEnableDisableItem] = useState({});
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({
            type: "FETCH_NOTIFICATION_REQUEST",
            payload: { postsPerPage, currentPage }
        })
    }, [postsPerPage, currentPage])
    console.log("the notificationList",notificationList)

    let pageNumbers = []

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage

    for (let i = 1; i <= Math.ceil(notificationList.totalData / postsPerPage); i++) {
        pageNumbers.push(i)
    }

    return (
        <Layout>
            <div>
                <div className="group_list">
                    <div style={{display: "flex",justifyContent: "space-between",alignItems:"center"}}>
                    <h4>Notification</h4>
                    <h4 className='mark-notification-viewed' style={{color: "white"}}  onClick={()=> {
                               dispatch({
                                   type: "MAKE_SEEN_NOTIFICATION_REQUEST",
                               })
                           }}>Mark Notification As Viewed</h4>
                    </div>
                    
                </div>
                <div className="user_groups">
                    <div className="user_groups--button_n_search">
                        <div className="user_groups--button_n_search--button">
                        
                            <label htmlFor="pageSize" style={{ marginLeft: "10px", fontSize: '18px', fontFamily: "bold", color: "#3e445e", marginRight: "-10px" }}> Per Page</label>
                            <select name="pageSize" onClick={(e) => setPostsPerPage(e.target.value)}>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="user_groups--button_n_search--search">
                            {/* <input type="text" placeholder="Search" /> */}
                        </div>
                    </div>
                    <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                    <div className="table-container">
                       <div className="notification--body" style={{display: "flex",flexDirection:"column",alignItems: "center"}}>

                           {notificationList.data !== undefined && notificationList.data.map((item)=> <div className='notificaion--container' >
                               <div className="notification--heading">
                               <h2>{item.title}</h2>
                               <p>{item.isViewed === "VIEWED" ? "Seen": "Not Seen"}</p>
                               </div>
                               <p className='notification--para'>{item.body}</p>
                           </div>)}
                       </div>
                        <div className="pagination" >
                            <p>Showing {indexOfFirstPost} to {indexOfLastPost > notificationList.totalData ? notificationList.totalData : indexOfLastPost} of {notificationList.totalData} entries</p>
                            <nav aria-label="..." >
                                <ul className="pagination" style={{maxWidth: "400px",overflowX:"auto"}}>
                                    {currentPage === 1 ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                                    </li>}
                                    {pageNumbers.map((x) =>
                                        <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                                    )}
                                    {currentPage == pageNumbers.length ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                                    </li>}
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </Layout>
    )
}


export default connect(mapStateToProps, null)(Notification)

