import axios from "axios";
import { getCookie } from "./FrontendHelper";

// FOR ADMIN LOGIN
const Token = getCookie("token");
export const API = axios.create({
  // baseURL: "http://13.127.139.254:8080/foocery/v1/api",
  // baseURL: "http://app.gurugfood.com:8080/foocery/v1/api",
  baseURL: `${process.env.REACT_APP_API}/foocery/v1/api`,
  headers: {
    Authorization: `Bearer ${Token}`,
  },
});
// REACT_APP_API = https://app.gurugfood.com:8443

// Remember to change base url
// 1. category image add
// 1. category image update
// 2. menu image add
// 2. menu image update
